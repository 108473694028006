<template lang='pug'>
  v-container#lock.fill-height(tag="section")
    v-row(justify="center")
      v-slide-y-transition(appear="")
        v-card.mt-6.mt-md-0(light="" max-width="100%" width="250")
          v-card-text.text-center
            v-snackbar(multiline top color='error' timeout='3500' dense v-model="isError")
              | {{errMsg}}
            v-avatar.mt-n12.mb-6.elevation-12(color="grey" size="88")
                v-img(v-if="user.image" :src="user.image")
                v-img(v-else src="@/assets/user.png")
            .display-2.font-weight-light.mb-3.text--darken-2.grey--text
              | {{user.data.email}}
            v-card-text.text-center.px-0
              //- error message
            v-text-field.mb-8(color="secondary" label="Enter Password" type='password' v-model='account.password'  v-on:keyup.enter="login")
            pages-btn(@click="login")
              | Unlock
</template>

<script>
  /* eslint-disable */
  export default {
    name: 'PagesLock',

    components: {
      PagesBtn: () => import('./component/Btn'),
    },

    data: () => ({
      account: {
        email: '',
        password: '',
      },
      isError: false,
      errMsg: ''
    }),

    computed: {
      user () {
        // console.log(this.$store.state.idle.user)
        return this.$store.state.idle.user
      },
      isMobile(){
        return window.innerWidth < 1024
      },
    },

    mounted () {},

    created () {
      const vm = this
      if (vm.$store.state.idle.user.data === undefined) {
        vm.$router.replace('/pages/login')
      }
    },

    methods: {
      login (e) {
        e.preventDefault()
        if (this.account.password === this.user.session_pass) {
          const email = this.user.data.email
          const uid = this.user.data.uid
          this.$store.commit('users/SET_USER', { email, uid })
          this.$store.dispatch('idle/setCurrentItem', false)
            setTimeout(() => {
              this.$router.replace('/')
            }, 2000)
        } else {
          this.isError = true
          this.errMsg = 'Invalid password entered.'
          setTimeout(() => {
            this.isError = false
          }, 5000)
        }
        /* this.account.email = this.user.data.email
        this.$store.dispatch('users/login', this.account)
          .then(function (res) {
            this.$store.dispatch('idle/setCurrentItem', false)
            setTimeout(() => {
              vm.$router.replace('/')
            }, 1000)
          })
          .catch(error => {
            // console.log(error.code)
            this.isError = true
            this.errMsg = error.code
            setTimeout(() => {
              this.isError = false
            }, 5000)
          }) */
      },
    },
  }
</script>
